import { CurrentPlatform, LinksNames } from './enums';
import { ITabsLinks, IUserMenuWeb } from './interfaces';
import { declOfNum } from './utils/declOfNum';

export const befreeLibraryUrl = 'https://cloud.melonfashion.ru/login';

export const befreeUrlValues = {
  auth: 'auth',
  update: 'update',
  dashboard: 'dashboard',

  login: 'login',

  avatar: 'avatar',
  posts: 'posts',
  messages: 'messages',
  employees: 'employees',
  departments: 'departments',
  favorites: 'favorites',
  training: 'training',
  library: 'library',

  notifications: 'notifications',
  all: 'all',
  comments: 'comments',
  likes: 'likes',

  menu: 'menu',

  shops: 'shops',
  shopList: 'shop-list',
  photoreports: 'photoreports',
  page: 'page',

  groups: 'groups',
  regions: 'regions',

  photos: 'photos',
  files: 'files',

  sections: 'sections',
  lesson: 'lesson',
  section: 'section',
  employeeDevelopment: 'employee-development',
  analytics: 'analytics',

  participants: 'participants',
};

export const plugImgSrc = '/assets/images/icons-svg/plug.svg';

export const footerMenuOptions = [
  {
    name: 'Лента',
    img: './assets/images/icons-svg/footer-menu/post-feed.svg',
    url: befreeUrlValues.posts,
  },
  {
    name: 'Избранное',
    img: './assets/images/icons-svg/footer-menu/favorites.svg',
    url: befreeUrlValues.favorites,
  },
  {
    name: 'Магазины',
    img: './assets/images/icons-svg/mobile-menu/shops.svg',
    url: befreeUrlValues.shops,
  },
  {
    name: 'Уведомления',
    img: './assets/images/icons-svg/footer-menu/notifications.svg',
    url: `${befreeUrlValues.notifications}/${befreeUrlValues.all}`,
  },
  {
    name: 'Меню',
    img: './assets/images/icons-svg/footer-menu/menu.svg',
    url: befreeUrlValues.menu,
  },
];

export const userMobileMenuOptions = [
  {
    name: 'Сотрудники',
    img: './assets/images/icons-svg/mobile-menu/stuff.svg',
    url: befreeUrlValues.employees,
  },
  {
    name: 'Отделы',
    img: './assets/images/icons-svg/mobile-menu/departments.svg',
    url: befreeUrlValues.departments,
  },
  {
    name: 'Обучение',
    img: './assets/images/icons-svg/mobile-menu/education.svg',
    url: befreeUrlValues.training,
  },
  {
    name: 'Библиотека',
    img: './assets/images/icons-svg/mobile-menu/library.svg',
    url: befreeLibraryUrl,
  },
];

export const postModalMenuOptions = [
  {
    name: 'Изображения',
    img: './assets/images/icons-svg/post-modal/images.svg',
  },
  {
    name: 'Файлы',
    img: './assets/images/icons-svg/post-modal/files.svg',
  },
  {
    name: 'Опрос',
    img: './assets/images/icons-svg/post-modal/interview.svg',
  },
  {
    name: 'Закрепить',
    img: './assets/images/icons-svg/post-modal/pin.svg',
  },
  {
    name: 'Открепить',
    img: './assets/images/icons-svg/post-modal/unpin.svg',
  },
];

export const userMenuOptionsWeb: IUserMenuWeb[] = [
  {
    name: 'Лента',
    img: './assets/images/icons-svg/footer-menu/post-feed.svg',
    url: befreeUrlValues.posts,
    // counterName: UnreadCountersStates.posts,
  },
  {
    name: 'Сотрудники',
    img: './assets/images/icons-svg/mobile-menu/stuff.svg',
    url: befreeUrlValues.employees,
  },
  {
    name: 'Отделы',
    img: './assets/images/icons-svg/mobile-menu/departments.svg',
    url: befreeUrlValues.departments,
  },
  {
    name: 'Магазины',
    img: './assets/images/icons-svg/mobile-menu/shops.svg',
    url: befreeUrlValues.shops,
  },
  {
    name: 'Избранное',
    img: './assets/images/icons-svg/footer-menu/favorites.svg',
    url: befreeUrlValues.favorites,
  },
  {
    name: 'Обучение',
    img: './assets/images/icons-svg/mobile-menu/education.svg',
    url: befreeUrlValues.training,
  },
  {
    name: 'Библиотека',
    img: './assets/images/icons-svg/mobile-menu/library.svg',
    url: befreeLibraryUrl,
  },
];

export const scrollToTopAnimationSpeed: number = 500;

export const formControlErrorCode = {
  required: 'required',
  wrongCredentials: 'wrongCredentials',
};

export const loginFormControls = {
  login: 'login',
  password: 'password',
};

export const shopLinks: ITabsLinks[] = [
  {
    name: 'МАГАЗИНЫ',
    url: befreeUrlValues.shopList,
  },
  {
    name: 'ФОТООТЧЁТЫ',
    url: befreeUrlValues.photoreports,
  },
];

export const errorCodes = {
  wrongCredentials: 'Wrong credentials',
};

export const pollTypesData = {
  public: {
    type: 'PUBLIC',
    text: 'Публичный опрос',
  },
  anonymous: {
    type: 'ANONYMOUS',
    text: 'Анонимный опрос',
  },
};

export const currentShopLinks: ITabsLinks[] = [
  {
    name: LinksNames.EMPLOYEES,
    url: befreeUrlValues.employees,
  },
  {
    name: LinksNames.PHOTOREPORTS,
    url: befreeUrlValues.photoreports,
  },
];

export const initialCurrentDepartmentsLinks: ITabsLinks = {
  name: LinksNames.EMPLOYEES,
  url: befreeUrlValues.employees,
};

export const currentRegionLinks: ITabsLinks[] = [
  {
    name: LinksNames.EMPLOYEES,
    url: befreeUrlValues.employees,
  },
  {
    name: LinksNames.SHOPS,
    url: befreeUrlValues.shopList,
  },
];

export const arrowRightSmall = 'assets/images/icons-svg/arrow-right-small.svg';
export const arrowRight = 'assets/images/icons-svg/arrow-right.svg';
export const arrowRightLight = 'assets/images/icons-svg/arrow-right-light.svg';
export const filtersIcon = 'assets/images/icons-svg/filters-icon.svg';
export const filtersIconWithOptions = 'assets/images/icons-svg/filters-icon-with-options.svg';
export const backButtonIcon = 'assets/images/icons-svg/back-button.svg';
export const photoReportPlugImgSrc = '/assets/images/icons-svg/photo-report-logo.svg';
export const removeChip = 'assets/images/icons-svg/remove-chip.svg';
export const locationImgSrc = 'assets/images/icons-svg/location.svg';
export const phoneNumberImgSrc = 'assets/images/icons-svg/phone-number.svg';
export const emailImgSrc = 'assets/images/icons-svg/email.svg';
export const camera = 'assets/images/icons-svg/camera.svg';
export const rotate = 'assets/images/icons-svg/rotate.svg';

export const successNotification = 'assets/images/icons-svg/success-notification.svg';
export const errorNotification = 'assets/images/icons-svg/error-notification.svg';

export const zoomValue = 1.1;

export const noDataText = 'По вашему запросу ничего не найдено';

export const searchLimit: number = 255;

export const FiltersOptionsNames: { [key: string]: string } = {
  REGIONS: 'регионы',
  SHOPS: 'магазины',
  CITIES: 'города',
  DEPTS: 'отделы',
  GROUPS: 'группы',
  POTENTIAL: 'потенциал развития',
  POSITIONS: 'выбор должности',
  TRAINER: 'тренер',
  STAGE: 'выбор этапа',
  TRAINER_ROLE: 'роль тренера',
};

export const wordDeclensions = {
  img: ['изображение', 'изображения', 'изображений'],
  answers: ['ответ', 'ответа', 'ответов'],
  employees: ['сотрудник', 'сотрудника', 'сотрудников'],
  files: ['файл', 'файла', 'файлов'],
  lessons: ['урок', 'урока', 'уроков'],
  photo: ['фотографию', 'фотографии', 'фотографий'],
};

export const successNotificationMessages = {
  deletePost: 'Запись удалена',
  pinPost: 'Запись закреплена',
  unpinPost: 'Запись откреплена',
  createPost: 'Запись успешно создана',
  editPost: 'Запись успешно изменена',
  addToFavorites: 'Добавлено в избранное',
  removeFromFavorites: 'Удалено из избранного',
  editPhotoReport: 'Фотоотчёт успешно изменен',
  deletePhotoReport: 'Фотоотчёт удален',
  createPhotoReport: 'Фотоотчёт успешно создан',
  savedPhotoToGallery: 'Изображение сохранено в галерею',
};

export const errorNotificationMessages = {
  default: 'Произошла ошибка',
  photoReportPhotoLimit: 'Можно загрузить только 100 фотографий',
  networkError: 'Отсутствует подключение к Интернету',
  postPhotoLimit: (limit: number) =>
    `Можно загрузить только ${limit} ${declOfNum(limit, wordDeclensions.photo)}`,
};

export const customScrollBarStyles = `
::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-track {
  background: var(--white);
}
::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px var(--grey);
  border-radius: 4px;
  background-color: var(--grey);
}
::-webkit-scrollbar-thumb:hover {
  background-color: var(--grey-text3);
}
.overscroll::before { bottom: 0 !important; }`;

export const favLinks = [
  {
    name: 'МАГАЗИНЫ',
    url: `/${befreeUrlValues.dashboard}/${befreeUrlValues.favorites}/${befreeUrlValues.shopList}`,
  },
  {
    name: 'ФОТОГРАФИИ',
    url: `/${befreeUrlValues.dashboard}/${befreeUrlValues.favorites}/${befreeUrlValues.photos}`,
  },
  {
    name: 'ФАЙЛЫ',
    url: `/${befreeUrlValues.dashboard}/${befreeUrlValues.favorites}/${befreeUrlValues.files}`,
  },
];

export const trainingLinksWeb = [
  {
    name: 'развитие сотрудника',
    url: `/${befreeUrlValues.dashboard}/${befreeUrlValues.training}/${befreeUrlValues.employeeDevelopment}`,
  },
  {
    name: 'аналитика по обучению',
    url: `/${befreeUrlValues.dashboard}/${befreeUrlValues.training}/${befreeUrlValues.analytics}`,
  },
];

export const trainingLinksMobile = [
  {
    name: 'обучение',
    url: `/${befreeUrlValues.dashboard}/${befreeUrlValues.training}/${befreeUrlValues.sections}`,
  },
];

export const notificationsLinks = [
  {
    name: 'все уведомления',
    nameMobile: 'все',
    url: `/${befreeUrlValues.dashboard}/${befreeUrlValues.notifications}/${befreeUrlValues.all}`,
  },
  {
    name: 'комментарии',
    url: `/${befreeUrlValues.dashboard}/${befreeUrlValues.notifications}/${befreeUrlValues.comments}`,
  },
  {
    name: 'лайки',
    url: `/${befreeUrlValues.dashboard}/${befreeUrlValues.notifications}/${befreeUrlValues.likes}`,
  },
  {
    name: 'фотоотчёт',
    url: `/${befreeUrlValues.dashboard}/${befreeUrlValues.notifications}/${befreeUrlValues.photoreports}`,
  },
];

export const trainingLevelsTypes = ['professional', 'personal'];

export const trainingGrades = ['1', '2', '3', '4', '5'];

export const TrainingLevelsSections = {
  professional: 'Hard Skills',
  personal: 'Soft Skills',
  careerOpportunities: 'Потенциал развития',
};

export const cssStyles = {
  AlertBtn: 'alert-button',
};

export const tableHeaderCellsNames = [
  {
    name: 'СОТРУДНИК',
    colSize: 5,
  },
  {
    name: 'ЭТАП',
    colSize: 1,
  },
  {
    name: 'СТАТУС',
    colSize: 1.7,
  },
  {
    name: 'ТРЕНЕР',
  },
  {
    name: 'СРОК ЗАПОЛНЕНИЯ',
    colSize: 2.2,
  },
];

export const developmentFormStepsInformation = [
  '1 – невозможно оценить',
  '2 – нет знаний и навыков',
  '3 – может выполнять с посторонней помощью',
  '4 – может без посторонней помощи, но может совершенствоваться',
  '5 – достигает требуемого уровня',
];

export const trainingStageFilters = [
  { name: 1, id: 1 },
  { name: 2, id: 2 },
  { name: 3, id: 3 },
];

export const trainingTrainerRolesFilters = [
  { name: 'Региональный тренер', id: 'REGIONAL_MANAGER_ROLE' },
  { name: 'TSM', id: 'TSM_ROLE' },
  { name: 'Может обучать', id: 'NO_ROLE' },
];

export const NotificationTypes = {
  trainingNotification: 'TRAINING_NOTIFICATION',
};

export const ignoredKeysInModalFilters = [
  'endTraining',
  'onlyUntrained',
  'stage',
  'customSort',
  'positionGroupId',
  'trainerId',
  'trainingRoleType',
];

export const mobileAppUrl: Record<CurrentPlatform, string> = {
  [CurrentPlatform.web]: '',
  [CurrentPlatform.ios]: 'https://testflight.apple.com/join/xPGbwRFe',
  [CurrentPlatform.android]: 'https://social.befree.ru/app/android/release.apk',
};

export const availableImageFormats =
  'image/*, .heic, .heif, image/heic, image/heif, image/heic-sequence, image/heif-sequence';

export const imagePlaceholderUrl = '/assets/images/icons-svg/image-placeholder.svg';
